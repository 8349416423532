const videos = [
  {
    title: "Birthday wish",
    url: "/elon.mp4",
  },
  {
    title: "Threads Rebrand",
    url: "/zuck.mp4",
  },
  {
    title: "Singing",
    url: "/obama.mp4",
  },
  {
      title: "Ant Interview",
      url: "/rogan.mp4"
  },
  {
    title: "Loves Ice Cream",
    url: "/biden.mp4",
  },
  {
    title: "Product Launch",
    url: "/trump.mp4",
  },
  {
    title: "Secrets to Looking Good",
    url: "/cruise.mp4",
  },
  {
    title: "Cooking Show",
    url: "/snoop.mp4",
  },
  {
    title: "Name Change",
    url: "/kanye.mp4",
  },
];

export default function Examples() {
  return (
    <div className="block mb-8">
      <p className="text-2xl font-semibold text-[#FFFFFF] mb-6">
        <span className="text-[#6E49FF]">Watch</span> Example Videos
      </p>
      <div className="flex flex-row flex-wrap justify-center gap-4 ">
        {videos.map((video) => (
          <div className="" key={video.url}>
            <div className="w-72 h-auto items-center justify-center mb-0">
                <video
                    playsInline={true}
                    autoPlay={false}
                    controls={true}
                    loop={false}
                    src={video.url}
                    type="video/mp4"
                   
                />
            </div>
            <p className="text-xl font-semibold text-[#FFFFFF]">
              {video.title}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
