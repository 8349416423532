import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import Nav from "./Nav";
import How from "./How";
import Terms from "./Terms";
import Share from "./Share";
import Admin from "./Admin";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Nav></Nav>
      <Routes>
        <Route exact path="/" element={<App></App>} />
        <Route path="/buy/:checkoutId" element={<App></App>} />
        <Route path="/j8jz5UYLuO" element={<Admin></Admin>} />
        <Route path="/how" element={<How></How>} />
        <Route path="/terms" element={<Terms></Terms>} />
        <Route path="/share/:id" element={<Share></Share>} />
      </Routes>
    </Router>
  </React.StrictMode>
);
